<template>
  <div
    class="relative fit"
    style="transition: transform .3s;"
    :style="terminalSettings.hideBarcodeField ? 'transform: translateX(-1000px)' : ''"
  >
    <barcode-input
      ref="barcodeField"
      :has-max-width="false"
      :settings="terminalSettings"
      :out-focused="outFocused"
      :auto-focus="true"
      @barcode="handleBarcode"
    />
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Components
import BarcodeInput from '../barcode-input/BarcodeInput.vue'

export default {
  name: 'TerminalHeader',
  emits: ['barcode'],
  components: {
    BarcodeInput
  },
  props: {
    outFocused: {
      type: Boolean,
      default () {
        return false
      }
    },
    title: {
      type: String,
      default () {
        return 'No title'
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'terminalSettings'
    ])
  },
  methods: {
    handleOpenCamera () {
      this.$refs.barcodeField.openCamera()
    },
    handleBarcode (barcode) {
      this.$emit('barcode', barcode)
    },
    onTop ({ reset }) {
      setTimeout(() => {
        reset()
      }, this.$appOptions.barcodeFieldTimeout || 5000)
    }
  }
}
</script>
